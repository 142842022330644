import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomPipePipe } from './custom-pipe/custom-pipe.pipe';
import { DisplayDatePipe } from './display-date/display-date.pipe';
import { UtcToIstPipe } from './utc-to-ist/utc-to-ist.pipe';
import { SafeHtmlPipe } from './safe-Html/safe-html.pipe';



@NgModule({
  declarations: [
    CustomPipePipe,
    DisplayDatePipe,
    UtcToIstPipe,
    SafeHtmlPipe
    
  ],
  imports: [
    CommonModule
  ],
  exports: [CustomPipePipe, DisplayDatePipe, UtcToIstPipe, SafeHtmlPipe]
})
export class FiltersModule { }
