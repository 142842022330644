import { environment } from "src/environments/environment";
//import { registerLicense } from '@syncfusion/ej2-base';
//registerLicense('ORg4AjUWIQA/Gnt2UlhhQlVMfV5DQmFJYVF2R2dJelR0c19EYkwgOX1dQl9nSH5RdEVnWnleeXdQTmQ=');


if ((window.location.host).indexOf('localhost') != -1) {
    // for pause socket
    // environment.config.webSocket = 'http://localhost:3000';
}



const rootPath = (dataVar: any) => {

    let url: any = environment.config.baseUrl;
    let path = url[dataVar] + environment.config.apiVer;
    return path;
}


export const rootEnv: any = environment;

export const apiUrl: any = {

    _common:
    {
        dropDown:
        {
            url: "/v1/MasterList/CommonBindDropFun", method: "POST"
        },
        clusterList:
        {
            url: "/v1/MasterList/Cluster/List?startpageindex/lastpageindex/search_key", method: "GET"
        },
        updateStatusCluster:
        {
            url: "/v1/MasterList/Cluster/Deactivate", method: "PUT"
        },
        updateCluster:
        {
            url: "/v1/MasterList/Cluster/Update", method: "PUT"
        },
        addCluster:
        {
            url: "/v1/MasterList/Cluster/Create", method: "POST"
        },
        companyList:
        {
            url: "/v1/MasterList/CompanyLocation/List?startpageindex/lastpageindex/search_key", method: "GET"
        },
        updateStatusCompany:
        {
            url: "/v1/MasterList/CompanyLocation/Deactivate", method: "PUT"
        },
        updateCompany:
        {
            url: "/v1/MasterList/CompanyLocation/Update", method: "PUT"
        },
        addCompany:
        {
            url: "/v1/MasterList/CompanyLocation/Create", method: "POST"
        },
        leadCampaignList:
        {
            url: "/v1/MasterList/ShowLeadCampaignList?page_index/page_size/lead_campaign_name", method: "GET"
        },
        updateStatusLeadCampaign:
        {
            url: "/v1/MasterList/UpdateStatusLeadCampaign", method: "POST"
        },
        UpdateLeadCampaign:
        {
            url: "/v1/MasterList/UpdateLeadCampaign", method: "POST"
        },
        addLeadCampaign:
        {
            url: "/v1/MasterList/CreateLeadCampaign", method: "POST"
        },
        leadSourceList:
        {
            url: "/v1/MasterList/ShowLeadSourceList", method: "POST"
        },
        updateStatusLeadSource:
        {
            url: "/v1/MasterList/UpdateStatusLeadSource", method: "POST"
        },
        UpdateLeadSource:
        {
            url: "/v1/MasterList/UpdateLeadSource", method: "POST"
        },
        addLeadSource:
        {
            url: "/v1/MasterList/CreateLeadSource", method: "POST"
        },
        leadMediumList:
        {
            url: "/v1/MasterList/Lead/LeadMediumList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        updateStatusLeadMedium:
        {
            url: "/v1/MasterList/Lead/DeactivateLeadMedium", method: "PUT"
        },
        UpdateLeadMedium:
        {
            url: "/v1/MasterList/Lead/UpdateLeadMedium", method: "PUT"
        },
        addLeadMedium:
        {
            url: "/v1/MasterList/Lead/CreateLeadMedium", method: "POST"
        },
        leadCheckList:
        {
            url: "/v1/MasterList/Lead/ShowLeadCheckList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        updateStatusLeadCheck:
        {
            url: "/v1/MasterList/Lead/DeactivateLeadCheckList", method: "PUT"
        },
        UpdateLeadCheckList:
        {
            url: "/v1/MasterList/Lead/UpdateLeadCheckList", method: "PUT"
        },
        addLeadCheckList:
        {
            url: "/v1/MasterList/Lead/CreateLeadCheckList", method: "POST"
        },
        lookupList:
        {
            url: "/v1/MasterList/ShowLookupList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        updateStatusLookup:
        {
            url: "/v1/MasterList/DeactivateLookup", method: "POST"
        },
        UpdateLookup:
        {
            url: "/v1/MasterList/UpdateLookup", method: "POST"
        },
        addLookup:
        {
            url: "/v1/MasterList/CreateLookup", method: "POST"
        },
        logHistory:
        {
            url: "/v1/MasterList/ShowLogHistory?mobile/page_index/page_size", method: "GET"
        },
        getDepartmentList:
        {
            url: "/v1/MasterList/Master/DepartmentList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        CreateDepartment:
        {
            url: "/v1/MasterList/Master/CreateDepartment", method: "POST"
        },
        UpdateDepartment:
        {
            url: "/v1/MasterList/Master/UpdateDepartment", method: "PUT"
        },
        DeactivateDepartment:
        {
            url: "/v1/MasterList/Master/DeactivateDepartment", method: "PUT"
        },
        getDesignationList:
        {
            url: "/v1/MasterList/Master/DesignationList?/startpageindex/lastpageindex/search_key", method: "GET"
        },
        CreateDesignation:
        {
            url: "/v1/MasterList/Master/CreateDesignation", method: "POST"
        },
        UpdateDesignation:
        {
            url: "/v1/MasterList/Master/UpdateDesignation", method: "PUT"
        },
        DeactivateDesignation:
        {
            url: "/v1/MasterList/Master/DeactivateDesignation", method: "PUT"
        },
        AgentTargetList:
        {
            url: "/v1/MasterList/Master/AgentTargetList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        CreateAgentTarget:
        {
            url: "/v1/MasterList/Master/CreateAgentTarget", method: "POST"
        },
        UpdateAgentTarget:
        {
            url: "/v1/MasterList/Master/UpdateAgentTarget", method: "PUT"
        },
        DeactivateAgentTarget:
        {
            url: "/v1/MasterList/Master/DeactivateAgentTarget", method: "PUT"
        },
        CreateKnowledge:
        {
            url: "/v1/MasterList/Master/CreateKnowledge", method: "POST"
        },
        UpdateKnowledge:
        {
            url: "/v1/MasterList/Master/UpdateKnowledge", method: "PUT"
        },
        DeactivateKnowledge:
        {
            url: "/v1/MasterList/Master/DeactivateKnowledge", method: "PUT"
        },
        KnowledgeList:
        {
            url: "/v1/MasterList/Master/KnowledgeList?page_index/page_size/", method: "GET"
        },
        KnowledgeListByKid:
        {
            url: "/v1/MasterList/Master/KnowledgeListByKid?kid", method: "GET"
        },
        GetClientDetails:
        {
            url: "/v1/MasterList/Master/GetClientDetails", method: "POST"
        },
        DeactivateClientMaster:
        {
            url: "/v1/MasterList/Master/DeactivateClientMaster", method: "PUT"
        },
        DeactivateSamplifyClientMaster:
        {
            url: "/v1/MasterList/Master/DeactivateSamplifyClientMaster", method: "PUT"
        },
        CreateClient:
        {
            url: "/v1/MasterList/Master/CreateClient", method: "POST"
        },
        UpdateCreateClient:
        {
            url: "/v1/MasterList/Master/UpdateCreateClient", method: "POST"
        },
        GetSalesDetails:
        {
            url: "/v1/MasterList/Master/GetSalesDetails", method: "POST"
        },
        ConfigList:
        {
            url: "/v1/MasterList/Master/ConfigList?startpageindex/lastpageindex/search_key", method: "GET"
        },
        CreateConfig:
        {
            url: "/v1/MasterList/Master/CreateConfig", method: "POST"
        },
        UpdateConfig:
        {
            url: "/v1/MasterList/Master/UpdateConfig", method: "PUT"
        },
        DeactivateConfig:
        {
            url: "/v1/MasterList/Master/DeactivateConfig", method: "PUT"
        },
        agentPrefrenceList:
        {
            url: "/v1/MasterList/Agent/LeadStatus/PrefrenceList", method: "POST"
        },
        deactivateAgentPreference:
        {
            url: "/v1/MasterList/Agent/", method: "PUT"
        },
        updateAgentPreference:
        {
            url: "/v1/MasterList/Agent/", method: "POST"
        },
        bulkUpload:
        {
            url: "/v1/MasterList/ClientBulkUploadExcel", method: "POST" 
        }
    },
    _user:
    {
        authLogin:
        {
            url: "/v1/Auth/Login", method: "POST"
        },
        authOTP:
        {
            url: "/v1/Auth/OTP", method: "POST"
        },
        verifyOTP:
        {
            url: "/v1/Auth/VerifyOTP", method: "POST"
        },
        resetPassword:
        {
            url: "/v1/Auth/ResetPassword", method: "POST"
        },
        authLogout:
        {
            url: "/v1/Auth/Logout", method: "POST"
        },


        getRolesAndRights: {
            url: "/v1/UserManagement/RolesAndRights", method: "GET"
        },
        userList:
        {
            url: "/v1/UserManagement/ShowUserList?page_index/page_size/emp_name/mobile", method: "GET"
        },
        updateUserStatus:
        {
            url: "/v1/UserManagement/UpdateUserStatus", method: "POST"
        },
        userCredential:
        {
            url: "/v1/UserManagement/ResetUserCredential", method: "POST"
        },
        createUser:
        {
            url: "/v1/UserManagement/CreateUser", method: "POST"
        },
        updateUser:
        {
            url: "/v1/UserManagement/UpdateUser", method: "POST"
        },




        roleList:
        {
            url: "/v1/UserManagement/ShowRoleList?page_index/page_size/role_name", method: "GET"
        },
        updateStatusRole:
        {
            url: "/v1/UserManagement/UpdateStatusRole", method: "POST"
        },
        updateRole:
        {
            url: "/v1/UserManagement/UpdateRole", method: "POST"
        },
        addRole:
        {
            url: "/v1/UserManagement/CreateRole", method: "POST"
        },


        menuList:
        {
            url: "/v1/UserManagement/ShowModuleList?page_index/page_size/module_name", method: "GET"
        },
        updateStatusMenu:
        {
            url: "/v1/UserManagement/UpdateStatusModule", method: "POST"
        },
        updateMenu:
        {
            url: "/v1/UserManagement/UpdateModule", method: "POST"
        },
        addMenu:
        {
            url: "/v1/UserManagement/CreateModule", method: "POST"
        },
        mappedMenu:
        {
            url: "/v1/UserManagement/ShowRoleModuleMapList?role_name", method: "GET"
        },
        createRoleModuleMap:
        {
            url: "/v1/UserManagement/CreateRoleModuleMap", method: "POST"
        },


        actionList:
        {
            url: "/v1/UserManagement/ShowActionList?page_index/page_size/action_name", method: "GET"
        },
        updateStatusAction:
        {
            url: "/v1/UserManagement/UpdateStatusAction", method: "POST"
        },
        updateAction:
        {
            url: "/v1/UserManagement/UpdateAction", method: "POST"
        },
        addAction:
        {
            url: "/v1/UserManagement/CreateAction", method: "POST"
        },
        mappedAction:
        {
            url: "/v1/UserManagement/ShowRoleActionMapList?role_name/page_index/page_size", method: "GET"
        },
        createRoleActionMap:
        {
            url: "/v1/UserManagement/CreateRoleActionMap", method: "POST"
        }


    },
    _leads: {

        getLeads:
        {
            url: "/v1/Lead/ShowAllLead", method: "POST"

        },
        getReminderleads:
        {
            url: "/v1/Lead/ShowAllCallReminderLead", method: "POST"

        },

        leadDetail:
        {
            url: "/v1/Lead/ShowLeadDetailsByid?lead_capture_id", method: "GET"

        },
        activityLog:
        {
            url: "/v1/Lead/ShowLeadActivities?lead_capture_id/page_index/page_size", method: "GET"
        },


        createLead:
        {
            url: "/v1/Lead/CreateLead", method: "POST"

        },
        updateLead:
        {
            url: "/v1/Lead/UpdateLead", method: "POST"

        },
        downloadPrescription:
        {
            url: "/v1/Lead/DownloadPrescription?fileurl", method: "GET"

        },
        releaseLead:
        {
            url: "/v1/Lead/LeadOpenandRelease?leadid", method: "GET"

        },
        leadActivity:
        {
            url: "/v1/Lead/LeadActivity", method: "POST"

        },
        completedLead:
        {
            url: "/v1/Lead/ShowAllCompletedLead", method: "POST"
        },
        completedLeadDetailsByid:
        {
            url: "/v1/Lead/ShowCompletedLeadDetailsByid?lead_capture_id", method: "GET"
        },
        ShowAttemptedLeadDetailsByid:
        {
            url: "/v1/Lead/ShowAttemptedLeadDetailsByid?lead_capture_id", method: "GET"
        },
        leadsCountDetails: {
            url: "/v1/Lead/GetLeadsCountDetails", method: "POST"
        },
        leadAssign:
        {
            url: "/v1/Lead/LeadAssignByHOD", method: "POST"
        },
        leadsUserOnHoldandStatus:
        {
            url: "/v1/Lead/LeadsUserOnHoldandRelease?is_on_hold", method: "GET"
        },
        leadActions: {
            url: "/v1/Lead/LeadActions", method: "GET"
        },
        agentList: {
            url: "/v1/Lead/GetActiveAgents?page_size/page_index", method: "GET"
        },
        GetLiveMonitoringOfOBAgent: {
            url: "/v1/Lead/GetLiveMonitoringOfOBAgent?filter_date_type/filter_from_date/filter_to_date/roleid/page_index/page_size", method: "GET"
        },
        showAllLeadByMobileNo: {
            url: "/v1/Lead/ShowAllLeadByMobileNo?mobile/page_size/page_index", method: "GET"
        },
        showAllBookingByMobileNo:
        {
            url: "/v1/Lead/ShowAllBookingByMobileNo?mobile/page_size/page_index", method: "GET"
        },
        ReminderNotification:
        {
            url: "/v1/Lead/CallReminderNotification", method: "GET"
        },
        freeLeadNotification:
        {
            url: "/v1/Lead/CountLeadsFreeUsersNotifications", method: "GET"
        },
        leadsfreeUsers:
        {
            url: "/v1/Lead/LeadsfreeUsers?action/page_index/page_size/filter_from_date/filter_to_date", method: "GET"
        },
        CountSamplifyStatusNotifications:
        {
            url: "/v1/Lead/CountSamplifyStatusNotifications?action/page_index/page_size", method: "GET"
        },
        SamplifyStatusNotifications:
        {
            url: "/v1/Lead/SamplifyStatusNotifications", method: "POST"
        },
        RoundRobinOnOff:
        {
            url: "/v1/Lead/RoundRobin_On_Off?is_round_robin", method: "GET"
        },
        leadPreferredAgent:
        {
            url: "/v1/Lead/LeadPreferredAgent?lead_id/preferred_agent", method: "GET"
        },
        readReminderNotification:
        {
            url: "/v1/Lead/ReadReminderNotification", method: "GET"
        },
        pendingLeads: {
            url: "/v1/Lead/GetUserActionDetails", method: "GET"
        },
        bulkLeadUpload:
        {
            url: "/v1/Lead/BulkUploadLeadFile", method: "POST"
        },
        getBulkLead:
        {
            url: "/v1/Lead/ShowAllBulkLead", method: "POST"
        },
        getAgentTarget:
        {
            url: "/v1/Lead/GetAgentTarget?page_size/page_index", method: "GET"
        },
        GetAgentTarget:
        {
            url: "/v1/Lead/GetAgentTarget?page_size/page_index", method: "GET"
        },
        GetLeadsCountDetails:
        {
            url: "/v1/Lead/GetLeadsCountDetails", method: "POST"
        },
        ShowAllAttemptedFreeLead:
        {
            url: "/v1/Lead/ShowAllAttemptedFreeLead", method: "POST"
        },
        GetAgentKnowledgeCenter: {
            url: "/v1/Lead/GetAgentKnowledgeCenter", method: "GET"
        },
        GetAgentKnowledgeCenterDashboard: {
            url: "/v1/Lead/GetAgentKnowledgeCenterDashboard", method: "GET"
        },
        UpdateAgentKnowledgeCenterDashboard: {
            url: "/v1/Lead/UpdateAgentKnowledgeCenterDashboard?kid", method: "GET"
        },
        GetAgentKnowledgeCenterDashboardCount: {
            url: "/v1/Lead/GetAgentKnowledgeCenterDashboardCount", method: "GET"
        },
        GetAverageBookingByMobileNo: {
            url: "/v1/Lead/GetAverageBookingByMobileNo?mobile", method: "GET"
        },
        ShowBookingNamesByMobileNo: {
            url: "/v1/Lead/ShowBookingNamesByMobileNo?mobile", method: "GET"
        },
        UserLoginDetails: {
            url: "/v1/Lead/UserLoginDetails?user_token", method: "GET"
        },
        UserBreakDetails: {
            url: "/v1/Lead/UserBreakDetails?user_token", method: "GET"
        },
        GenerateCRMMISReport: {
            url: "/v1/Lead/GenerateCRMMISReport?fromdate/todate/mis_type", method: "GET"
        },
        GetPatientListbyMobile: {
            url: "/v1/Lead/GetPatientListbyMobile?mobile", method: "GET"
        },
        GetAmeyoCallRecordsCount: {
            url: "/v1/Lead/GetAmeyoCallRecordsCount?user_token", method: "GET"
        },
        GetLeadDuration: {
            url: "/v1/Lead/GetLeadDuration?lead_id", method: "GET"
        },
        CheckMobileNoDND: {
            url: "/v1/Lead/CheckMobileNoDND?mobile", method: "GET"
        },
        GetAgentProductivityMis: {
            url: "/v1/Lead/GetAgentProductivityMis?from_date/to_date", method: "GET"
        }
    },
    _booking:
    {
        findCCLab:
        {
            url: "/v1/LeadBooking/FindCCLab", method: "POST"
        },
        showTestList:
        {
            url: "/v1/LeadBooking/ShowTestList", method: "POST"
        },
        addAddress:
        {
            url: "/v1/LeadBooking/AddAddress", method: "POST"
        },
        addressByMobile:
        {
            url: "/v1/LeadBooking/GetAddressByMobile", method: "POST"
        },
        addBooking:
        {
            url: "/v2/LeadBooking/AddBooking", method: "POST"
        },
        getBookingList: {
            url: "/v2/LeadBooking/ShowbookingList", method: "POST"
        },
        getBookingDetails: {
            url: "/v2/LeadBooking/ShowBookingDetails", method: "POST"
        },
        addChildBooking: {
            url: "/v2/LeadBooking/AddChildBooking", method: "POST"
        },
        getBookingDetail: {
            url: "/v2/LeadBooking/ShowBookingDetails", method: "POST"
        },
        RescheduleBooking: {
            url: "/v1/LeadBooking/RescheduleBooking", method: "POST"
        },
        CancelBooking: {
            url: "/v1/LeadBooking/CancelBooking", method: "POST"
        },
        ShowBookingPaymentDetails: {
            url: "/v1/LeadBooking/ShowBookingPaymentDetails", method: "POST"
        },
        CreatePaymentLink: {
            url: "/v1/LeadBooking/CreatePaymentLink", method: "POST"
        },
        UpdateBooking: {
            url: "/v2/LeadBooking/UpdateBooking", method: "POST"
        },
        ShowBookingHistory: {
            url: "/v1/LeadBooking/ShowBookingHistory", method: "POST"
        },
        profileTest: {
            url: "/v1/LeadBooking/ShowProfileTest", method: "POST"
        },
        finalBooking:
        {
            url: '/v1/LeadBooking/CompleteBooking', method: "POST"
        },
        SamplifyWebhookDetail: {
            url: '/v1/SamplifyData/SamplifyWebhookDetail', method: "POST"
        },
        getPaymentList: {
            url: "/v1/LeadBooking/ShowBookingPaymentSummamary", method: 'POST'
        },
        labsoulRegistration: {
            url: "/v2/LeadBooking/LabsoulRegistration", method: 'POST'
        }


    },
    _payment: {
        orderResponce:
        {
            url: "/v1/PaymentOrder/GetResponse", method: "POST"
        },
        VerifyPaymentStatus: {
            url: '/v1/PaymentOrder/VerifyPaymentStatus', method: "POST"
        }

    },

    _inbound: {
        interaction:
        {
            url: "/v1/Dashboard/Dashboard/Interaction?view/from/to", method: "GET"
        },
        interactionList:
        {
            url: "/v1/Interaction/GetAll?filterID/assigned_to/status/date_from/date_to/category_id/sub_category_id/last_updated_at/channelType/channel/page_index/page_size/sort_by/order_by", method: "GET"
        },
        interactionDetail:
        {
            url: "/v1/Interaction/", method: "GET"
        },
        categoryList:
        {
            url: "/v1/Common/GetCategory", method: "GET"
        },
        departmentList: {
            url: "/v1/Common/GetDepartment", method: "GET"
        },
        userDetails: {
            url: "/v1/Common/User", method: "GET"
        },
        ticketList: {
            url: "/v1/Ticket/GetAll?filterID/assigned_to/departmentId/status/date_from/date_to/category_id/sub_category_id/user_department_id/escalation_type/page_index/page_size/sort_by/order_by/", method: "GET"
        },
        ticketNotes: {
            url: "/v1/Ticket/", method: "GET"
        },
        updateInt:
        {
            url: "/v1/Interaction/", method: "PUT"
        },
        createInt:
        {
            url: "/v1/Interaction/Create", method: "POST"
        },
        interactionSearch:
        {
            url: "/v1/Interaction/Search?filterId/searchText/page_index/page_size/sort_by/order_by", method: "GET"
        },
        ticketSearch:
        {
            url: "/v1/Ticket/Search?filterId/searchText/page_index/page_size/sort_by/order_by", method: "GET"
        },
        createTicket:
        {
            url: "/v1/Interaction/", method: "POST"
        },
        searchTicketByInt:
        {
            url: "/v1/Interaction/", method: "GET"
        },
        ticketDetail:
        {
            url: "/v1/Ticket/", method: "GET"
        },
        getChildTickets:
        {
            url: "/v1/Ticket/", method: "GET"
        },
        updateTicket:
        {
            url: "/v1/Ticket/", method: "PUT"
        },
        getWorkType:
        {
            url: "/v1/Common/GetWorkType", method: "GET"

        },
        updateWorkType:
        {
            url: "/v1/Common/UpdateWorkType", method: "PUT"

        },
        updateSingleWorkType:
        {
            url: "/v1/Common/UpdateSingleWorkType", method: "PUT"

        },
        assignToDepartment:
        {
            url: "/v1/Ticket/AssignToDepartment/", method: "POST"
        },
        uploadAttachment:
        {
            url: "/v1/Attachment/AddAttachment", method: "POST"
        },
        getAgentList:
        {
            url: "/v1/Common/Agents", method: "GET"
        },
        resolved:
        {
            url: "/v1/Interaction/Resolved", method: "PUT"
        },
        resolvedTicket:
        {
            url: "/v1/ticket/Resolved", method: "PUT"
        },
        reopenTicket:
        {
            url: "/v1/ticket/Reopen", method: "PUT"
        },
        bulkAssign:
        {
            url: "/v1/Interaction/BulkSelfAssign", method: "PUT"
        },
        bulkClose:
        {
            url: "/v1/Interaction/BulkClose", method: "PUT"
        },
        ticketConversation:
        {
            url: "/v1/Ticket/", method: "POST"
        },
        getTicktConversation:
        {
            url: "/v1/Ticket/", method: "GET"
        },
        intConversation:
        {
            url: "/v1/Interaction/", method: "POST"
        },
        getIntConversation:
        {
            url: "/v1/Interaction/", method: "GET"
        },
        updateCustomer:
        {
            url: "/v1/Customer/", method: "PUT"
        },
        getCustomerData:
        {
            url: "/v1/Customer/", method: "GET"
        },
        createCustomerData:
        {
            url: "/v1/Customer/Create", method: "POST"
        },
        downloadAttachment:
        {
            url: "/v1/Common/DownloadAttachment", method: "POST"
        },
        bulkEmail: {
            url: "/v1/Common/SendBulkEmail", method: "POST"
        },
        associatedInteractions: {
            url: "/v1/Ticket/", method: "GET"
        },
        associatedTickets: {
            url: "/v1/Interaction/", method: "GET"
        },
        clusterDetails: {
            url: "/v1/Common/Cluster", method: "GET"
        },
        getAllReports: {
            url: "/v1/Reports/GetReportTypes", method: "GET"
        },
        getReportData: {
            url: "/v1/Reports/GetReport?reportTypeId/AgentID/status/date_from/date_to/page_index/page_size/Channel/Source/Status/Category/SubCategory/ClosedBy/InternalEscalationLevel/forwarded_To_Department/SubDepartment/ParentLabName/Cluster/forwarded_by_Agent/Interaction_Number/Contact_Number/Email_Id/ticket_number/Assigned_to",
            
            
            
            method: "GET"
        },
        bulkTicketAssign:
        {
            url: "/v1/Ticket/BulkSelfAssign", method: "PUT"
        },
        addReminder:
        {
            url: "/v1/Ticket/", method: "POST"
        },
        senderList:
        {
            url: "/v1/Common/Senders", method: "GET"
        },
        searchCustomer:
        {
            url: "/v1/Customer/Search?first_name/last_name/mobile/email/page_index/page_size", method: "GET"
        },
        escalationList:
        {
            url: "/v1/Category/Get?page_index/page_size/", method: "GET"
        },
        updateCategory:
        {
            url: "/v1/Category/", method: "PUT"
        },
        addCategory:
        {
            url: "/v1/Category/Create", method: "POST"
        },
        deactivateCategory:
        {
            url: "/v1/Category/", method: "PUT" 
        },
        getDepartmentCluster:
        {
            url: "/v1/Department/GetCluster", method: "GET"  
        },
        inboundDepartmentList:
        {
            url: "/v1/Department/Get?Cluster/Search/SearchFilter/Active/page_index/page_size/", method: "GET"
        },
        deactivateDepartment:
        {
            url: "/v1/Department/", method: "PUT" 
        },
        updateInboundDepartment:
        {
            url: "/v1/Department/", method: "PUT" 
        },
        updateEscalationSLA:
        {
            url: "/v1/Ticket/", method: "POST" 
        },
        updateIntEscalationSLA:
        {
            url: "/v1/Interaction/", method: "POST" 
        }, 
        getTicketEscalationList:
        {
            url: "/v1/Ticket/GetEscalationStatus/", method: "GET" 
        },
        updateEscalationRestart:
        {
            url: "/v1/Ticket/", method: "POST" 
        }, 
        addErrorLog:
        {
            url: "/v1/Log/AddLogs", method: "POST" 
        }
        
        


    },
    _patientInfo: {
        patientData: {
            url: "/v1/TestReport/Patient?searchId/searchValue", method: "GET"
        },
        testSampleDetails: {
            url: "/v1/TestReport/TestSampleDetails", method: "GET"
        },
        testDetails: {
            url: "/v1/TestReport/TestDetails", method: "GET"
        }
    },
    _ameyo: {
        ShowCallDetails: {
            url: "/v1/AmeyoCall/ShowCallDetails", method: "POST"
        },
        ShowChatDetailsByMobile: {
            url: "/v1/WA/ShowChatDetailsByMobile", method: "POST"
        }
    },
    _lims_utility: {
        LimsUtilityLogin: {
            url: "/v1/Lims/Login", method: "POST"
        },
        authLogout:
        {
            url: "/v1/Lims/Logout", method: "POST"
        },
        patientInfo: {
            url: "/v1/Lims/GetBasicPatientInfo", method: "POST"
        },
        getSearchPatientInfo: {
            url: "/v1/Lims/GetSearchPatientInfo", method: "POST"
        },
        testInfo: {
            url: "/v1/Lims/GetPatientTestInfo", method: "POST"

        },
        sampleInfo: {
            url: "/v1/Lims/GetPatientSampleInfo", method: "POST"

        },
        receiptList: {
            url: "/v1/Lims/GetPatientReceiptList", method: "POST"

        },
        getReceipt: {
            url: "/v1/Lims/GetPatientReceipt", method: "POST"

        },
        patientPeport: {
            url: "/v1/Lims/GetPatientReport", method: "POST"

        },
        getMISReport: {
            url: "/v1/Lims/GetMISReport", method: "POST"
        },
        GetCenterList: {
            url: "/v1/Lims/GetCenterList", method: "POST"
        },
        GetClientList: {
            url: "/v1/Lims/GetClientList", method: "POST"
        },
        GetLabSampleReport: {
            url: "/v1/Lims/GetLabSampleReport", method: "POST"
        },
        GetLabSampleReportDetail:
        {
            url: "/v1/Lims/GetLabSampleReportDetail", method: "POST"
        },
        GetLabSamplePendingReportDetail:
        {
            url: "/v1/Lims/GetLabSamplePendingReportDetail", method: "POST"
        },
        getReconcileReport:
        {
            url: "/v1/Lims/GetReconcileReport", method: "POST"
        },
        GetClientType: {
            url: "/v1/Lims/GetClientType", method: "POST"
        },
        GetClientListByClientType: {
            url: "/v1/Lims/GetClientListByCliientType", method: "POST"
        },
        GetReconcileMISReport: {
            url: "/v1/Lims/GetReconcileMISReport", method: "POST"
        },
        GetSearchPatientInfoCRM: {
            url: "/v1/Lims/GetSearchPatientInfoCRM", method: "POST"
        }
    },
    _utility:
    {
        receiptMail:
        {
            url: "/v1/Utility/SendReceiptMail", method: "POST"
        },
        receiptSMS:
        {
            url: "/v1/Utility/SendReceiptSMS", method: "POST"
        },
        reportMail:
        {
            url: "/v1/Utility/SendReportMail", method: "POST"
        },
        reportSMS:
        {
            url: "/v1/Utility/SendReportSMS", method: "POST"
        }

    },
    _socket: {
        insertSocketId:
        {
            url: "/Socket/CRM_Insert_SocketInDB", method: "POST"
        },
        deleteSocketId:
        {
            url: "/Socket/CRM_Delete_SocketInDB", method: "POST"
        },
        updateLeadPageAfterManualAssign:
        {
            url: "/Socket/CRM_Socket1", method: "POST"
        }
    },



    of(dataVar: any) {

        let urlPath;

        for (let i in this) {
            if (this[i][dataVar]) {
                let fullUrl = rootPath(i) + this[i][dataVar].url
                urlPath = { ...this[i][dataVar], url: fullUrl };
                break;
            }

        }
        return urlPath;
    }


}




export const menuIcon = {
    'dashboard': 'bi bi-display',
    'user-management': 'bi bi-people',
    'role-management': 'bi bi-fingerprint',
    'leads': "bi bi-journal-text",
    'booking': "bi bi-clipboard-check",
    "company-management": "bi bi-wrench-adjustable",
    "active-agent": "bi bi-person-check",
    "activity": "bi bi-bookmark-check",
    "acknowledgement": "bi bi-megaphone",
    "notes": "bi bi-envelope-paper",
    'bulk-email': "bi bi-envelope",
    'interaction-lead': "bi bi-journal-richtext",
    'tickets': "bi bi-pass",
    'reports': "bi bi-clipboard-data",
    "mis-management": 'bi bi-markdown',
    "client-management": 'bi bi-person-video3',
    "sales-management": 'bi bi-journal-bookmark-fill',
    "ameyo-management": "bi bi-headset",
    "chat-management": "bi bi-chat",
    "lims-utility": 'bi bi-tools',
    "notification-management": 'bi bi-bell',
    "agent-summary": 'bi-clock-history',
    "escalation-list": 'bi-clipboard2-data',
    "bulk-upload": 'bi-cloud-upload'


}