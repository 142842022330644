import { Injectable, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, finalize, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { apiUrl } from "src/environments/config";
import { CommonService } from "./common.service";




const headerOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class WebapiService {

  private httpHeaderOptions = headerOptions;
  mainLoader: BehaviorSubject<any> = new BehaviorSubject(0);
  barLoader: BehaviorSubject<any> = new BehaviorSubject(0);
  userDetail: BehaviorSubject<any> = new BehaviorSubject(false);
  userTarget: BehaviorSubject<any> = new BehaviorSubject({});
  userNotes: BehaviorSubject<any> = new BehaviorSubject([]);
  isShowAckPop: BehaviorSubject<any> = new BehaviorSubject(false);
  isShowAckNotification: BehaviorSubject<any> = new BehaviorSubject(false);

  isAdmin: any = false;
  inboundUser:any = false;
  ameyoLeftPos: BehaviorSubject<any> = new BehaviorSubject(false);
  ameyoCall: BehaviorSubject<any> = new BehaviorSubject(false);
  ameyoLogin: BehaviorSubject<any> = new BehaviorSubject(false);
  ameyoLogOut :BehaviorSubject<any> = new BehaviorSubject(false);

  userRights:any = false;
  appMenu:any = false;

  toastMsg: any = [];

  constructor(private activeRoute: ActivatedRoute, private http: HttpClient, public router: Router, private common: CommonService) {

  }




  postFormData(dataVar: any): Observable<any>  {   
    this.updateHeaders(false);
    this.setloaderValue(dataVar, true);
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }); 
  
     let options = { headers: headers };  
     if(dataVar.config.method=="PUT"){
     return this.http.put(dataVar.config.url, dataVar.payload.parmObj, options).pipe(
      finalize(() => { this.afterApiResponce(dataVar);}),
      tap({
        error: (err) => {this.onApiError(err,dataVar.config.url);}
       
       })
       );;
     }
     else 
     return this.http.post(dataVar.config.url, dataVar.payload.parmObj, options).pipe(
      finalize(() => { this.afterApiResponce(dataVar);}),
      tap({
        error: (err) => {this.onApiError(err,dataVar.config.url);}
       
       })
       );; 
 

  }  
  

  downladFile(dataVar: any): Observable<any>  {    
    this.setloaderValue(dataVar, true);
    let headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken') }); 
  
     let options = { headers: headers ,
      responseType: 'blob' as 'json' };  
     let urlRequest = dataVar.config.url;
      if(dataVar.config.url.indexOf('?')<0)
      {
        this.updateHeaders(dataVar.payload);
      }
      else
      {
        urlRequest = this.updateUrlRequest(dataVar);
      }
      
      
      return this.http.post(dataVar.config.url, dataVar.payload.parmObj, options).pipe(
        finalize(() => { this.afterApiResponce(dataVar);}),
        tap({
          error: (err) => {this.onApiError(err,dataVar.config.url);}
         
         })
      
      );
 
  }  



  showToast(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    if (options.type == 'notification') {
      this.toastMsg = [];
      this.toastMsg.push({ textOrTpl, ...options });
    } else {
      this.toastMsg.push({ textOrTpl, ...options });
    }
  }

  removeToast(toast: any) {
    let self = this;
    setTimeout(() => { this.toastMsg = this.toastMsg.filter((t: any) => t !== toast) }, 1000);

  }

  clearAllToast() {
    this.toastMsg.splice(0, this.toastMsg.length);
  }




  emitUserDetail() {
   
    localStorage.setItem("pageLocation", location.pathname);
   
  }

  
  async getRolesAndRights() 
  {

    let outData:any = false;
    
    if(this.userRights && Object.keys(this.userRights).length > 0 && this.appMenu?.length>0)
    {
      let uDetail:any ={name:this.userDetail.value.name, role_name:this.userDetail.value.role_name, ameyo_login:this.userDetail.value.ameyo_login, is_ameyo_required: this.userDetail.value.is_ameyo_required, popup_notification_time: this.userDetail.value.popup_notification_time, logout_buffer_time:  this.userDetail.value.logout_buffer_time}; 
      outData = {rights:this.userRights,menus:this.appMenu, user_details:[uDetail]};
    }
    else
    {
      let dataParam = apiUrl.of('getRolesAndRights');
      this.pageLoaderValue(true);
      this.updateHeaders(false);

      let responce:any =  await this.http.get(dataParam.url,this.httpHeaderOptions).toPromise();
      
      this.pageLoaderValue(false);
      if(responce.is_success)
      {
       outData = responce.data;
      }
      

    }

    if(outData)
    {
      let uData = { appMenu: outData.menus, rights: outData.rights, ...outData.user_details?.[0]};
      this.userDetail.next(uData);

      this.userRights = outData.rights;
      this.appMenu = outData.menus;
  
      this.isAdmin = (this.userDetail.value?.role_name == "Admin") ? true : false;
      this.inboundUser = ((this.userDetail.value?.role_name)?.indexOf('IB') != -1) ? true : false;
    }
    return outData;

    

  }


  private updateHeaders(dataVar: any) {
    let headerOptions: any = new HttpHeaders({
      'Content-Type': 'application/json',
      'x-auth-token': '123456789',
      'Cache-Control': 'no-cache; no-store; must-revalidate',
      'Pragma': 'no-cache',
      'Strict-Transport-Security': 'max-age=31536000',
      'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
    });

    if(dataVar?.isCrmLogin){
      headerOptions['Authorization'] = 'Bearer ' + localStorage.getItem('limsAccessToken');
    }

    if (dataVar?.params) {
      headerOptions.params = dataVar.params
    }
    this.httpHeaderOptions = { headers: headerOptions };



  }


  apiResponse(dataVar: any): Observable<any> {
    this.updateHeaders(false);

    this.setloaderValue(dataVar, true);



    if (dataVar.config.method == "GET") {
      let urlRequest = dataVar.config.url;
      if (dataVar.config.url.indexOf('?') < 0) {
        this.updateHeaders(dataVar.payload);
      }
      else {
        urlRequest = this.updateUrlRequest(dataVar);
      }


      return this.http.get(urlRequest, this.httpHeaderOptions).pipe(
        finalize(() => { this.afterApiResponce(dataVar); }),
        tap({
          error: (err) => { this.onApiError(err,dataVar.config.url); }

        })

      );

    }
    else if (dataVar.config.method == "POST") {
      return this.http.post(dataVar.config.url, dataVar.payload, this.httpHeaderOptions).pipe(
        finalize(() => { this.afterApiResponce(dataVar); }),
        tap({
          error: (err) => { this.onApiError(err,dataVar.config.url); }

        })
      );
    } else if (dataVar.config.method == "PUT") {
      return this.http.put(dataVar.config.url, dataVar.payload, this.httpHeaderOptions).pipe(
        finalize(() => { this.afterApiResponce(dataVar); }),
        tap({
          error: (err) => { this.onApiError(err,dataVar.config.url); }

        })
      );
    }
    else if (dataVar.config.method == "LOCAL_GET") {

      return this.http.get("assets/localJson.json").pipe(finalize(() => { this.afterApiResponce(dataVar); }))

    }
    else {
      return this.http.get("assets/config/apiError.json").pipe(finalize(() => { this.afterApiResponce(dataVar); }))
    }

  }


  async getDropDoenList(dataVar: any) {
    let dataParam: any = {
      config: apiUrl.of('dropDown'),
      payload: dataVar.postParam,
      barLoader: true
    }

    let kk = await this.apiResponse(dataParam).subscribe({
      next: res => {

        console.log(res.data)
        return res.data

      }
    });

    return kk;

  }


  onApiError(dataVar: any,requestedURL?:any) {
    let txt = dataVar?.error?.status_message || dataVar?.error?.title;
    this.showToast(txt, { type: 'error' });
    if (dataVar?.status == "401") {
      this.add401ErrorLog(requestedURL);
      localStorage.clear();
      this.router.navigate(['login']).then(() => 
      {
        //window.location.reload();
      });
    }
  }

  add401ErrorLog(requestedURL:any) {
    let dataVar: any = "addErrorLog"
    let serialnumber = this.common.getTokenId("")?.certserialnumber;
    let useId = serialnumber.substring(6, serialnumber.length);
  let dataParam: any = {
    config: {
      url: apiUrl.of(dataVar).url,
      method: apiUrl.of(dataVar).method
    },
    payload: {
      "uid":  useId ?? 0,
      "message": requestedURL
    }
  }

   this.apiResponse(dataParam).subscribe({
    next: res => { 
    }
  });
 
  }
  

  setloaderValue(dataVar: any, status: any) {

    if (!dataVar.childLoader && !dataVar.barLoader) {
      this.pageLoaderValue(status);
    }
    else if (dataVar.barLoader) {
      this.barLoaderValue(status);
    }
    else {
      dataVar.childLoader.val = status;
    }


    if (dataVar.toast) {
      // this.showToast("apple new add", {type:"error"});
    }

  }


  pageLoaderValue(dataVal: any) {
    let newVal = dataVal ? (this.mainLoader.value + 1) : (this.mainLoader.value - 1);
    this.mainLoader.next(newVal);

  }

  barLoaderValue(dataVal: any) {
    let newVal = dataVal ? (this.barLoader.value + 1) : (this.barLoader.value - 1);
    this.barLoader.next(newVal);

  }




  updateUrlRequest(dataVar: any) {
    let urlRequest = (dataVar.config.url).split("?");
    let baseUrl = urlRequest[0];
    let requestKey = urlRequest[1];

    if (requestKey && dataVar.payload) {
      let allKeys = requestKey.split("/");

      let keyValArr: any = [];

      allKeys.forEach((k: any) => {

        let payVal = dataVar.payload[k] ? dataVar.payload[k] : "";
        let keyVal = k + "=" + payVal;
        keyValArr.push(keyVal);

      });


      let updatedUrl = baseUrl + "?" + keyValArr.join("&");
      return updatedUrl;

    }
    else {
      return baseUrl;
    }


  }



  afterApiResponce(dataVar: any) {

    this.setloaderValue(dataVar, false);

  }


  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {

        resolve({ lon: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });

  }




  async getItemList(dataVar: any) {
    let url = apiUrl._common.lookupList;
    let data = {
      "startpageindex": 1,
      "lastpageindex": 10000,
      "SearchbyKey": "gender"
    }


    let dataParam: any = {
      config: url,
      payload: data
    }


    return this.apiResponse(dataParam).subscribe({
      next: res => {

        if (res.issuccess) {

          return res.data.lookupList;

        }

      },
      error: error => {
        //console.log(error);

        return [];

      }
    });

  }


  setUserTarget(dataVar: any) {
    this.userTarget.next(dataVar);
  }

  setUserNotes(dataVar: any) {
    this.userNotes.next(dataVar);
  }

  setAckMessages(dataVal: boolean){
    this.isShowAckPop.next(dataVal);
  }

  setAckNotification(dataVal: boolean){
    this.isShowAckNotification.next(dataVal);
  }



  isLoggedIn() {

    return !!localStorage.getItem("accessToken");

  }

}
